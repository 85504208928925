.reports-container {
    margin-top: 50px;
    height: 95vh;
    @media (max-width: 599px){
        height: 100%;
    }

    .selectors {
        background-color: #efefef;
        height: 100%;
        width: 100%;
        padding-top: 20px;
        padding-bottom: 20px;

        .title {
            font-weight: 800;
            font-size: 24px;
        }

        .dropdown-container {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            padding: 20px;
            width: 100%;

            .show-charts-button {
                margin-top: 15px;
            }

            .helper-text {
                font-size: 16px;
                font-weight: 800;
                color: black;
            }

            .select {
                width: 100%;
                // min-width: 200px;
                // margin-right: 10px;
                background-color: white;

                .menu-item {
                    
                }
            }

            .active-chip {
                background-color: #212121;
                color: white;
                padding: 5px;
                margin-top: 5px;
                border-radius: 6px;
                width: 80%;
                height: 35px;
                margin-left: auto;
                margin-right: auto;
                vertical-align: center;

                display: flex;
                direction: row;
                align-items: center;
                justify-content: center;
                
                .label {
                    font-weight: 500;
                    flex-grow: 1;
                    margin-left: 15px;
                }

                .remove {
                    border: 0px solid #212121;
                    float: right;
                    
                    .icon {
                    border: 0px solid #212121;

                        color: white;

                    }
                }
            }

            .dates {
                margin-top: 20px;

                .date-title {
                    font-weight: 800;
                }

                .helper {
                    font-size: 14px;
                    font-weight: 400;
                    color: black;
                }

                .date-selector {
                    background-color: white;
                }

                .button {
                    margin-top: 10px;
                }

                .error-message {
                    color: red;
                    text-align: center;
                    margin-top: 15px;
                }
            }
        }
    }

    .table-container {

        padding: 10px;
        margin-top: 20px;

        .header-cell {
            background-color: #212121;
            color: white;
            text-align: center;
            padding: 5px;
        }

        .header-cell-start {
            background-color: #212121;
            color: white;
            text-align: center;
            padding: 5px;
            
            & :hover {
                cursor: pointer;
            }
        }

        .body-cell {
            text-align: center;
        }
    }

    .charts-container {
        display: inline-flex;
        flex-direction: row;
        width: 100%;

        .chart-element {
            width: 200px;
        }

    }

}