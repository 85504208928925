.manage-fbo {
    margin-top: 47px;
 

    .grid-container {
        height: 100vh;
        @media (max-width: 599px){
            height: 100%;
        }

        .select-fbo-grid {
            background-color: #efefef;
            height: 100%;
            width: 100%;
            
            .select-control {
                width: 100%;
                padding: 15px;
                margin-top: 30px;
                padding-bottom: 30px;

                .select {
                    background-color: white;
                    
                }

            }

            .button-control {
                width: 100%;
                padding: 15px;
                margin-top: 30px;
                padding-bottom: 30px;

                .button-text{
                    
                }

            }

        }
    }


}
.appbar-container {
    position: sticky;
    width: 100%;
    margin-top: 10px;
    
    
    @media (max-width: 599px){
        width: 100%;
        margin-top: 0px;
    }

    .manageFBO-appbar {
        display: flex;
        direction: row;
        align-items: center;
        justify-content: center;
        
    }

    .usersTable {
        margin-top: 20px;  
        

        .table {
            min-width: 600px;

            .table-head {
                background-color: #212121;

                .tr {
                    
                    .th-cell {
                        color: white;
                        text-align: center;
                    }

                }
                
            }
            .table-body {
                .tr {

                    .tr-cell {
    
                        // color: red;
                        vertical-align: middle;
                        
                        
                    }
                }
            }
        }

    }
    
    .save-item {
        margin: 15px;

        .button {

        }

        .circular-progress {
            margin: auto;
            padding: 5px;
        }

        .error {
            text-align: center;
            color: red;
            padding: 10px;
        }
    }

    .fbo-settings {
        // margin-top: 10px;

        .title {
            margin-top: 10px;
            font-weight: 600;
        }

        .form {

            .text-field {
                width: 100%;
            }

            .add-button {
                margin-top: 10px;
                margin-bottom: 10px;
            }
        }

    }

    .ownership-table {

        .title {
            font-weight: 600;
            text-align: center;
            margin-top: 20px;
            margin-bottom: 5px;
        }

        .table-container {
            margin-bottom: 15px;

            .table-head {
                
                .header-cell {
                    text-align: center;
                    background-color: #212121;
                    color: white;

                }
            }

            .table-body {

                .body-cell {
                    text-align: center;

                }
            }
        }
    }

    .accounts-table {

        .accounts-title {
            font-weight: 600;
        }

        .table-container {

            .table-head {

                .header-cell {
                    text-align: center;
                    background-color: #212121;
                    color: white;
                }
            }

            .table-body {

                .body-cell {
                    text-align: center;
                }
            }

        }

        .add-account {
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }

}

