.planes-box {
    margin-top: 100px;

    .grid-item {
        padding: 10px;
    
        .expansion-panel {
            @media (max-width: 600px) {
                width: 90vw;
                min-width: 350px;
            }
            @media (min-width: 601px) {
                width: calc(80vw - 50px);
                min-width: 600px;
            }
            .title {
                display: inline;
                flex-grow: 1;
                
            }
            .details {
                padding: 5px;
                margin: 5px;
                
            }

            .img {
                margin: auto;
                width: 100%;
                max-width: 250px;
            }

            .link {
                display: flex;
                align-items: center;
                
            }
        }
    }
}

.planeProfile-box {
    margin-top: 100px;

    .grid-item {
        padding: 10px;

        .paper {
            @media (max-width: 600px) {
                width: 90vw;
                min-width: 350px;
                

                .header {
                    .link {
                        display: none;
                    }
                }
            }
            @media (min-width: 601px) {
                width: calc(80vw - 50px);
                min-width: 600px;

                .header {
                    display: flex;
    
                    .title {
                        display: flex;
                        flex-grow: 1;
                        
                    }
    
                    .link {
                        display: flex;
                        align-items: center;
                    }
                }
            }
            padding: 20px;

            .divider {
                margin: 10px 0 10px 0;
            }

            .img {
                margin: auto;
                width: 100%;
                max-width: 600px;
                padding: 20px;
            }

            .data-table {

                .data-cell {
                    width: 50%;
                }
            }
        }
    }
}