.payment-area { 

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 30px;

    .payment-card {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border: solid black 2px;
        border-radius: 6px;
        width: 300px;
        height: 100px;
        // margin-top: 10px;
        margin-left: 5px;
        margin-right: 5px;
        margin-bottom: 5px;
        
        // overflow: auto;
    
        &:hover {
            background-color: rgb(248, 248, 248);
            border: solid black 3px;
            cursor: pointer;
        }
    
        .brand {
            padding-top: 10px;
            padding-left: 30px;
            height: 40px;
            text-transform: uppercase;
        }
    
        .expiry {
            display: inline-block;
            margin-left: 20px;
            font-weight: 900;
        }
    
        .last4 {
            display: inline-block;
            margin-left: 20px;
            font-weight: 900;
        }
        
        .add {
            display: flex;
            direction: row;
            align-items: center;
            justify-content: center;
            width: 100%;
            // margin: 50%;
            font-size: 1.5em;
            text-transform: uppercase;
        }
        
    }
    
}