.event-wrapper {
    margin-top: 80px;
    padding: 10px;

    
    .button-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        

        .go-back {
            color: #00acc1;
            margin-left: 10px;
            margin-right: 10px;
        }
        .update {
            margin-left: 10px;
            margin-right: 10px;     
        }
    }
    .error-message {
        color: red;
    }
    .form-container {
        padding: 15px;
        background-color: #212121;
        // min-height: 400px;
        max-width: 500px;
        border-radius: 25px;

        .form {
            display: flex;
            direction: row;
            align-items: center;
            justify-content: center;
            // margin-top: 15px;
            padding: 15px;
            max-width: 500px;
            border-radius: 25px;

            .title {
                width: 100%;
                margin-bottom: 15px;
                max-width: 500px;
            }

            .subtitle {
                font-size: 15px;
                color: black;
                font-weight: 800;
            }

            .person {
                font-size: 14px;
                margin-top: 0px;
                margin-bottom: 10px;
            }

            .helper-text {
                // margin: 5px;
                font-size: 15px;
                color: black;
            }

            .hobbs {
                width: 200px;
                margin: 5px;
            }

            .RichEditor-root {
                box-sizing: border-box;
                border: 1px solid #ddd;
                cursor: text;
                padding: 20px;
                border-radius: 10px;
                margin-bottom: 10px;
                box-shadow: inset 0px 1px 8px -3px #ABABAB;
                background: #fefefe;
                min-width: 320px;
                min-height: 400px;

                .RichEditor-controls {
                    display: inline-flex;
                   
                    .RichEditor-styleButton {
                        padding: 5px;
                        margin: 0px;
                        border-radius: 0px;

                    }
                    .RichEditor-styleButtonRichEditor-activeButton{
                        background-color: #212121;
                        color: white;
                        margin: 0px;
                        padding: 5px;
                        border-radius: 0px; 
                        
                    }
                }

                .DraftEditor-root {
                    margin-top: 20px;
                    text-align: left;
                    padding: 5px;
                    min-height: 200px;
                }
            
            }

            .editor :global(.public-DraftEditor-content) {
                min-height: 140px;
            }
        
        }
    }

    .backdrop {
        z-index: 100;
    }

    
}
