.flight-forms {
    margin-top: 80px;
    padding-left: 10px;
    padding-right: 10px;

    .text-field {
        width: 100%;
        margin-bottom: 5px;
    }
    .submit-button {
        margin-top: 10px;
    }
    
    .log-containers {
        margin-bottom: 50px;

        .table {
        min-width: 600px;

        .table-head {
            background-color: #212121;

            .tr {
                
                .th-cell {
                    color: white;
                    text-align: center;
                }

                .tr-cell {
                    
                    
                }
            }

        }
    }
    

    }
    .balance {
        display: flex;
        direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-evenly;
        margin-bottom: 20px;
        margin-top: 10px;

        .current-balance {
            color: #c62828;
            font-weight: 800;
        }

        .no-balance {
            color: green;
            font-weight: 800;
        }

        .amount-spent {
            font-weight: 800;
        }
    }

}
