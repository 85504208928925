.root {

    .appbar-main{
        display: flex;

        .img-wrapper{
            display: inline-flex;
            width: 100%;
            align-items: center;
            justify-content: center;

            .navbar-icon {
                -webkit-filter: invert(1);
                filter: invert(1);
                max-width: 150px;
                // transform: rotate(180deg);
                //flex-grow
                // align-self: center;
                }

        }
    
        .appbar-title {
            font-style: italic;
            font-weight: 600;
            flex-grow: 1;
            
    
        }
    
        .button {
            margin-right: 0;
        }
    
    }
}



.custom-drawer {

    .close-drawer {
        float: right;
    }
    .link-list {
        width: 275px;

        .link-item {
            :hover {
                color: #ff5722;
            }
        }
    }

    .forms {

        .text-field {
            padding: 5px 10px 5px 10px;
            width: 100%;
        }

        .custom-button {
            margin-left: 00px;
            margin-right: 00px;
            padding: 10px;
            width: 100%;
            border-radius: 0px;
            // display: block;
        }

        .error-alert {
            padding: 10px;
            color: red;
            text-align: center;
        }
    }
}


