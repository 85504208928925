

.settings-forms {
    margin-top: 80px;

    .setting-item {
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 40px;

    }
}