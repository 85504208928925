.main-wrapper {
    margin-top: 80px;
    padding-left: 20px;
    padding-right: 20px;

    .profile-forms {
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 20px;

        .text-field {
            width: 100%;
            margin-bottom: 5px;
        }
        .submit-button {
            margin-top: 10px;
        }
    }
    
    .select-field {
        width: 100%;
    }
    .event-container {
        padding: 5px;
        
        .my-event {
            background-color: #ffc107;
            text-align: center;
            padding: 5px;
            margin: 5px;
            border-radius: 10px;
            cursor: pointer;
        }
        .my-event:hover {
            background-color: #f44336;
        }
    }
}

.appbar{
    margin-top: 57px;

    .link {
        &:hover {
            color: white;
            opacity: 1;
            font-weight: 700;
            text-decoration: none;
        }
    }
    
}
.StripeElement {
    width: 100%;
    padding: 15px;
}