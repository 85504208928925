// html {
//     background-color: #212121;
// }

.onboard-container {
    height: -webkit-fill-available;
    min-height: 100vh;
    background-color: #212121;

    

    .onboard-form {
        margin: 10px;
        padding-bottom: 20px;

        .title-img {
      
            img {
                width: 80%;
                margin-top: 20px;
                margin-bottom: 10px;
            }

        }

        

        .form {
            // padding: 15px;
            padding-right: 15px;
            padding-left: 15px;
            padding-top: 15px;

            .title {
                font-weight: 800;
                // padding-top: 20px;
                font-size: 18px;
            }

            .button-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                

                .button {
                    margin-left: 15px;
                    margin-right: 15px;
                    margin-top: 15px;
                }
            }

            .helper-text {
                font-size: 16px;
                font-weight: 800;
                color: black;

                .info-icon {
                    max-width: 20px;
                    margin-left: 5px;
                }

            }

            .field {
                margin-bottom: 15px;
            }
            
            .field-personal {
                margin-bottom: 3px;
            }

            .table-container {
                margin-bottom: 20px;

                .header-cell {
                    background-color: #212121;
                    color: white;
                    text-align: center;
                    padding: 5px;
                }
    
                .cell {
                    margin: 1px;
                    padding: 1px;
    
                    .cell-input {
                        width: 100%;
                        font-size: 8px;
                    }
                }
            }

            .create-button {
                // margin-top: 20px;
                // max-width: 200px;
                margin-left: auto;
                margin-right: auto;
            }

            .button-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                margin-bottom: 20px;

                .button {
                    margin-left: 15px;
                    margin-right: 15px;
                    margin-top: 15px;
                }
            }

            .error-message {
                color: red;
                text-align: center;
                margin-top: 10px;
            }
            
            .submit-button {
                margin-top: 20px;
                max-width: 200px;
                margin-left: auto;
                margin-right: auto;
            }

        }
    }

    .request-subtitle {
        // margin-top: 50px;
        // margin-bottom: 0px;
        font-weight: 500;
    }

    .request-access {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        // margin-top: 15px;
        max-width: 600px;

        .form {
            padding: 15px;

            .helper-text {
                font-size: 16px;
                font-weight: 800;
                color: black;
                margin-left: 0px;

            }

            .name-field {
                margin-bottom: 5px;
            }

            .inquiry-field {
                margin-bottom: 5px;
                // min-height: 300px;
            }

            .success-message {
                color: green;
                padding: 5px;
                margin-top: 10px;
            }

            .circular-progress {
                margin: 15px;
                width: 100%;
                margin-left: auto;
                margin-right: auto;
            }
        }

    }
}