.pay-bill {
    padding: 10px;

    .owe-amount {
        width: 100%;
        text-align: center;
        font-size: 1.5em;
        margin-bottom: 20px;
    }

    .selection {

        .label-container {

            display: flex;
            direction: row;
            align-items: center;

            .label {
                &:hover {
                    font-weight: 700;
                }
            }

            .custom-text {
                opacity: 1;
                margin-left: 10px;
                margin-top: -3px;
                padding: 3px 0 3px 10px;
                min-width: 125px;
            }
        }

        .radio-button {
            margin-right: 10px;
            
            .checked-icon {
                width: 20px;
                height: 20px;
                border: 2px solid #212121;
                border-radius: 50%;
                background-color: #212121;
            }

            .unchecked-icon {
                width: 20px;
                height: 20px;
                border: 2px solid #212121;
                border-radius: 50%;
            }

        }
    }

    .payment-error {
        color: red;
    }

    .finalize {
        text-align: center;
        margin: 10px 20px 10px 20px;
    }
} 