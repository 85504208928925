.home-container {
  min-height: 100vh;
  background-color: #212121;

  .card-container {
    width: 100%;
    
    .title {
      
      
      img {
        width: 80%;
        margin-top: 20px;
        margin-bottom: 10px;
      }
    }

    .form {

      .button {
        margin-top: 15px;
      }

      .button-container {
        display: inline-flex;
        justify-content: space-around;
        margin-top: 10px;
        margin-bottom: -20px;
        
      }

      .forgot-title {
        font-weight: 600;
        margin-bottom: 20px;
      }
    }
  }

}

  