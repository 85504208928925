@import '~@fullcalendar/core/main';
@import '~@fullcalendar/daygrid/main';
@import '~@fullcalendar/timegrid/main';
@import '~@fullcalendar/resource-timeline/main';
@import '~@fullcalendar/resource-timeline/main';
@import '~@fullcalendar/list/main';
@import '~@fullcalendar/timeline/main';

.fc-highlight {
    background: #cfd8dc;
    opacity: 0.8;
}

.fc-today {
    background: #b2ebf2;
    opacity: 0.4;

} 

.fc-center {
    font-size: 10px;
}

.fc-toolbar.fc-header-toolbar {
    margin-bottom: 10px;
}

.btn.btn-primary {
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 5px;
    padding-right: 5px;
    background-color: #212121;
}

.btn.btn-primary.active {
    background-color: #00acc1 !important;
    color: white !important;
}


th.fc-day-header.fc-today {
    background: none;
    // color: black !important;
    opacity: 1;
    
}

   

.fc-resource-cell {

    @media (max-width: 599px) {
        font-size: 10px;
    }
}


.calendar-container {
    margin-top: 80px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    
    .MuiAccordion-root {
        margin-bottom: 20px;
    }
    
    .filter-container {

        background-color: #212121;
        padding: 5px;
        // margin: 10px;
        border-radius: 10px;
        width: 100%;

        .type-button {
            background-color: white;
        }

        .type-button-selected {
            background-color: #00acc1;
            color: white;
        }

        .flight-filter {
            
            padding: 10px;
            margin: 10px;
        
            .text-field {
        
                margin-bottom: 5px;
        
            }

            .alert {
                padding: 5px;
                color: red;
            }

            .checkbox {

            }
        
        }
    }
}
