.dialog {

    .dialog-title {
        display: flex;
        align-items: center;
        margin-bottom: -10px;

        .title {
            flex-grow: 1;
        }
    }

    .dialog-content {

        .form {

            .fields-text {
                color: red;
            }

            .circular-progress {
                margin: auto;
                padding: 5px;
            }

            .add-button {
                margin-top: 10px;
            }

            .reset-button {
                margin-top: 2px;
            }

            .checkbox {
                margin-top: 20px;
            }

            .switch-group {
                margin-top: 15px;
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                justify-content: flex-start;
            }
        }
    }
}

.remove-dialog {

    .button-container {
        display: flex;
        justify-content: center;

        .button {
            padding: 5px;
        }
    }

    .text {
        color: red;
        padding-top: 5px;
    }
}

.edit-flight-dialog {

    .title {

    }

    .field-container {
        width: 100%;
        padding: 5px;
        margin: 5px;

        .text-field {
            width: 100%;
            margin-bottom: 5px;
        }

    }

    .button-container {
        display: flex;
        width: 100%;
        margin-top: -15px;

        .button {
            width: 50%;
            margin: 5px;
        }

        .block-button {
            width: 100%;
            margin: 5px;
        }
    }
    .alert {
        padding: 5px;
        color: red;
        margin-top: -10px;
    }

}

.responsible-party-add {

    .dialog-title {
        display: flex;
        align-items: center;
        margin-bottom: -10px;

        .title {
            flex-grow: 1;
        }
    }

    .dialog-content {

        .form {


            .helper-text {

            }
            .text-field {

            }
            
            .section-header {
                margin-top: 10px;
                font-weight: 600;
            }

            .dob {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                justify-content: space-around;

                .section {
                    margin: 2px;

                    .helper-text {

                    }
                    .text-field {

                    }
                }
            }
        }

        .save-button {
            
        }
    }

    .backdrop {
        z-index: 100;
    }

}

.add-bank-dialog {

    .dialog-title {
        display: flex;
        align-items: center;
        margin-bottom: -10px;

        .title {
            flex-grow: 1;
        }
    }

    .dialog-content {

        .form {

            .section-header {
                margin-top: 10px;
                font-weight: 600;
            }

            .button-group {
                display: flex;
                direction: row;
                align-items: center;
                justify-content: space-evenly;
                margin-bottom: 10px;

                .button {
                    margin-top: 5px;
                    margin-left: 5px;
                    margin-right: 5px;
                   
                }
            }

            .submit {
                margin-top: 10px;
            }

            .inline {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                justify-content: space-around;

                .section {
                    margin: 2px;
                    flex-grow: 1;
                    
                    .text-field {
                        width: 100%;
                    }

                }

                .cvc {
                    margin: 2px;
                    max-width: 100px;
                }
            }

        }

        .message {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            color: red;
            font-weight: 600;
        }
    }
}